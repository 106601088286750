

export default function Tiktok({ width, height, ...props }){
    return(
        <div {...props}>
            <svg 
                width={width}
                height={height} 
                viewBox="0 0 21 25" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0H14.97C15.186 1.11719 15.78 2.52656 16.8225 3.925C17.8425 5.29531 19.1955 6.25 21 6.25V9.375C18.3705 9.375 16.395 8.10312 15 6.51719V17.1875C15 18.7327 14.5601 20.2431 13.736 21.5279C12.9119 22.8126 11.7406 23.814 10.3701 24.4053C8.99968 24.9966 7.49168 25.1513 6.03683 24.8499C4.58197 24.5484 3.2456 23.8044 2.1967 22.7118C1.14781 21.6192 0.433503 20.2271 0.144114 18.7116C-0.145275 17.1962 0.00324963 15.6253 0.570907 14.1978C1.13856 12.7702 2.09986 11.5501 3.33323 10.6916C4.56659 9.83319 6.01664 9.375 7.5 9.375V12.5C6.60999 12.5 5.73996 12.7749 4.99994 13.29C4.25991 13.8051 3.68314 14.5371 3.34254 15.3937C3.00195 16.2502 2.91284 17.1927 3.08647 18.102C3.2601 19.0113 3.68869 19.8465 4.31802 20.5021C4.94736 21.1576 5.74918 21.6041 6.6221 21.7849C7.49501 21.9658 8.39981 21.873 9.22208 21.5182C10.0443 21.1634 10.7471 20.5626 11.2416 19.7917C11.7361 19.0209 12 18.1146 12 17.1875V0Z" fill="#DCFFDF"/>
            </svg>
        </div>
    )
}