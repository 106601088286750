import { SignatureMainDiv } from "./styles";



export default function Signature(){
    return(
        <SignatureMainDiv>
            <strong>Monte </strong> Produções | 2024
        </SignatureMainDiv>
    )
}