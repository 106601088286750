
export default function Youtube({ width, height, ...props }){
    return(
        <div {...props}>
            <svg 
               width={width}
               height={height} 
                viewBox="0 0 25 18" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5805 0H12.7195C14.004 0.0048047 20.5122 0.0528519 22.267 0.536525C22.7975 0.684139 23.2809 0.971866 23.6689 1.37095C24.0569 1.77004 24.3359 2.26651 24.4781 2.81075C24.6359 3.41934 24.7469 4.22493 24.8219 5.05614L24.8375 5.22271L24.8719 5.63911L24.8844 5.80568C24.9859 7.26951 24.9984 8.64045 25 8.93994V9.06006C24.9984 9.37076 24.9844 10.8346 24.8719 12.3593L24.8594 12.5274L24.8453 12.694C24.7672 13.6101 24.6515 14.5198 24.4781 15.1893C24.3359 15.7335 24.0569 16.23 23.6689 16.629C23.2809 17.0281 22.7975 17.3159 22.267 17.4635C20.4544 17.9632 13.5649 17.9984 12.6102 18H12.3883C11.9054 18 9.90843 17.9904 7.81455 17.9167L7.54891 17.9071L7.41296 17.9007L7.14576 17.8895L6.87856 17.8783C5.14407 17.7998 3.49241 17.6733 2.73142 17.4619C2.20112 17.3144 1.71784 17.0269 1.32985 16.6281C0.94186 16.2293 0.662734 15.7332 0.520345 15.1893C0.346897 14.5214 0.231264 13.6101 0.153135 12.694L0.140634 12.5258L0.128133 12.3593C0.050571 11.2741 0.00783692 10.1866 0 9.0985L0 8.9015C0.0031252 8.55717 0.015626 7.3672 0.100006 6.05392L0.110944 5.88896L0.115632 5.80568L0.128133 5.63911L0.16251 5.22271L0.178136 5.05614C0.253141 4.22493 0.364085 3.41774 0.521908 2.81075C0.664058 2.26651 0.943078 1.77004 1.33108 1.37095C1.71909 0.971866 2.20249 0.684139 2.73298 0.536525C3.49397 0.328321 5.14563 0.200196 6.88012 0.120117L7.14576 0.108906L7.41453 0.0992973L7.54891 0.0944926L7.81611 0.0832817C9.30325 0.0342793 10.791 0.00704971 12.2789 0.00160164L12.5805 0ZM10.0006 5.14103V12.8574L16.4963 9.0008L10.0006 5.14103Z" fill="#DCFFDF"/>
            </svg>
        </div>
    )
}