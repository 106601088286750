
export default function Mail({ width, height, ...props }){
    return(
        <div {...props}>
            <svg 
                width={width}
                height={height}
                viewBox="0 0 23 17"
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                <path d="M0.071875 2.20292C0.216801 1.57709 0.573067 1.01832 1.08232 0.618104C1.59158 0.217889 2.22365 -4.32004e-05 2.875 6.42336e-09H20.125C20.7764 -4.32004e-05 21.4084 0.217889 21.9177 0.618104C22.4269 1.01832 22.7832 1.57709 22.9281 2.20292L11.5 9.0865L0.071875 2.20292ZM0 3.82075V13.8847L8.34181 8.84425L0 3.82075ZM9.71894 9.67583L0.274563 15.3807C0.507907 15.8657 0.876195 16.2753 1.33656 16.5619C1.79693 16.8486 2.33044 17.0005 2.875 17H20.125C20.6695 17.0001 21.2028 16.8479 21.6629 16.561C22.123 16.274 22.491 15.8643 22.724 15.3793L13.2796 9.67442L11.5 10.7468L9.71894 9.67583ZM14.6582 8.84567L23 13.8847V3.82075L14.6582 8.84567Z" fill="#DCFFDF"/>
            </svg>
        </div>
    )
}