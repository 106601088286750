
export const StatementsDescriptionText = {
    deus: "Cremos num só Deus, Onipotente, Onisciente e Onipresente, Criador e Governador do Universo e do homem, eternamente existente, em três pessoas: Pai, Filho e Espírito Santo. (Ex 20:2-5; 1Co 8:6; 1Jo 5:7; Ap 4:11)."
    ,biblia: "Cremos na inspiração divina e na inerrância da Bíblia Sagrada, única regra de fé e prática, inspirada, infalível e autorizada por Deus, para a vida e o caráter cristão, à qual nada se pode acrescentar ou retirar. (2Pe 1:21; 2Tm 3:15-17; 1T 52:13)."
    ,condicao: "Cremos que o homem originalmente foi criado livre e em plena comunhão com Deus. Porém, dando lugar à desobediência, sob o engano de Satanás, herdou o pecado, carecendo assim da regeneração em Cristo, pela Palavra e pelo Espírito Santo para a sua salvação. (Gn 3; Rm 1:18-32; 5:12-19)."
    ,salvador: "Cremos que Jesus Cristo é o Salvador prometido, Filho amado de Deus, sendo Um com Ele em propósito e natureza. Veio a Terra como homem através de nascimento virginal e viveu sem pecado, para prover ao homem, através do sangue derramado em Sua morte na cruz e da Sua ressurreição, o único caminho de salvação do pecado e da condenação eterna (Jo 1:14; 10:30; Mt 1:23; 1Pe 2:22; 1Co 15:3-4; Mt 28:6; Fl 2:9)."
    ,plano: "A salvação do homem acontece a partir de seu arrependimento e batismo mediante a fé em Cristo. A evidência exterior dessa salvação é uma vida de retidão e santidade perante os homens (Jo 3:16; Ef 4:24; Tt 2:12)."
    ,batismoAguas: "Cremos que o batismo nas águas é a expressão de nosso pacto com Deus, através de Cristo (Gn 6:18; 1Pe 3:20-21; 1Co 10:16-17). Todos os que creem em Cristo como Senhor e Salvador e se arrependem de seus pecados devem ser batizados, para que neles se cumpra a justiça de Deus (Mt 3:15; 28:19; At 10:47-48; Rm 6:4)."
    ,batismoEspirito: "Cremos no batismo do Espírito Santo como o cumprimento da promessa de Deus para revestimento de poder e virtude sobre todos os que creem (Lc 24:29; At 1:4-5,8; 2:4; 8:12-17)."
    ,dons: "Cremos que Deus nos quer fazer como Jesus Cristo, Seu Filho. Para tanto Ele deseja que nosso caráter seja renovado continuamente a fim de produzirmos fruto que consistentemente testemunhe de Sua graça. Cremos que Deus equipa os crentes com o poder do Espírito Santo para fazermos as mesmas obras que Jesus fez, através dos dons sobrenaturais (1Co 12:4-11; 2Pe 1:3-8)."
    ,casamento: "Cremos que o casamento foi instituído por Deus, entre um homem e uma mulher, para cumprimento de seu propósito de constituir famílias segundo o modelo de Cristo e da Igreja, sendo, portanto indissolúvel. Para tanto, o homem e a mulher devem relacionar-se em santidade e amor, conforme Ef 5:25-32, discernindo em sua intimidade o que é consistente com a Palavra (Bíblia) e com sua maturidade, para mútua edificação."
    ,esperanca: "A ressurreição daqueles que dormiram em Cristo e seu arrebatamento, junto com os vivos que permanecerem até a vinda do Senhor, é nossa bendita esperança. Aguardamos Seu retorno prometido em poder e glória, para buscar a Sua Igreja e com ela reinar (1Ts 4:17; Rm 8:23; 1Co 15:51-52)."
    ,santificacao: "É o processo de separação do cristão do que é mau e de sua consagração a Deus (Rm 12:1-2; 1Ts 5:23; Hb 13:12). As Escrituras ensinam uma vida de santidade, sem a qual ninguém verá o Senhor. Pelo poder do Espírito Santo somos capacitados a obedecer a Deus para sermos santos (Hb 12:4; 1Pe 1:15-16)."
    ,juizo: "Cremos que haverá um juízo final no qual todos serão julgados de acordo com suas obras. Todo aquele que não for achado escrito no livro da vida, herdará a condenação eterna (Ap 20.11-15)."
}